.uqb-table-footer {
    padding: 16px;
    background: var(--bg-body-100);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .load-more {
        cursor: pointer;
        color: var(--primary-100);
    }
}
