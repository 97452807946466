@use "sass-rem" as *;

$anim-time: 200ms;

.rdc-menu-item {
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    min-height: rem(40px);
    padding: 0 rem(18px);
    display: flex;
    position: relative;
    align-items: center;
    text-align: left;
    text-decoration: none;
    gap: rem(12px);
    border: none;
    color: var(--primary-100);
    outline:none;

    &:focus {
        background: var(--bg-body-400);
    }

    &:after {
        content: '';
        display: block;
        background: var(--border-outline-100);
        height: 1px;
        left: rem(16px);
        position: absolute;
        bottom: 0;
        width: calc(100% - 32px);
        z-index: 100;
    }

    &:last-of-type {
        border-bottom: none;

        &:after {
            display: none;
        }
    }

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
}
