@use "sass-rem" as *;
/* DEFAULT STYLE */

.cdk-global-overlay-wrapper {
    overflow-y: auto;
}

.select-menu-button {
    width: 100%;
    padding: 0 rem(37px) 0 rem(12px) !important;
    color: var(--text-600);
    justify-content: flex-start;

    span {
        font-weight: var(--font-weight-normal);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        display: inline-block;
        width: 100%;
    }

    &:hover {
        background: var(--bg-body-100);
    }

    &:focus {
        border: solid 1px var(--focus);
    }

    &[disabled] {
        color: var(--text-400);
        background: var(--disabled);
    }
}


.rdc-new-menu {
    margin: 0;
    display: flex;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    position: relative;
    border: none;

    &:focus, &:focus-visible {
        outline: none;
    }
}


.rdc-context-menu-item {
    all: unset;
    user-select: none;
    flex: auto;
    box-sizing: border-box;
    cursor: pointer;
    padding: rem(8px);
    color: var(--text-600);
    font-weight: var(--font-weight-normal);
    display: flex;
    align-items: center;
    gap: rem(8px);
    width: 100%;

    span {
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 100%;
    }

    &:focus, &:hover {
        background: var(--bg-body-500);
    }
}

.rdc-context-menu-item.active {
    background: var(--bg-body-500);

    span {
        color: var(--text-1100);
    }
}

.rdc-context-menu-item[disabled], .rdc-context-menu-item.disabled {
    cursor: default;
    background: transparent;
    color: var(--text-300);

    span {
        color: var(--text-300);
    }

    &:focus, &:hover {
        background: transparent;
    }
}

.cdk-overlay-container {
    z-index: 1001;
}

cdk-dialog-container {
    position: relative;
    background: white;
    box-shadow: var(--drop-shadow-200);
}

.rdc-dialog {
    animation: openDialog forwards 200ms;
}

.rdc-dialog-title {
    justify-content: center;
    height: rem(24px);
}

.rdc-dialog-no-border {
    cdk-dialog-container {
        border: 0;
    }
}

.rdc-cloud-popup {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 150ms, opacity 150ms linear;
}

.rdc-menu-dialog {
    animation: growDown 150ms ease-in-out forwards;
    transform-origin: top center;
    display: block;
    background: var(--bg-body-100);
    transform: scaleY(1) scaleX(1);
    border: solid var(--border-width) var(--border-outline-100);
    border-radius: var(--border-radius);
    overflow: hidden;

    cdk-dialog-container {
        padding: rem(16px) 0;
    }

    &.no-animate {
        animation: none;
    }
}

.rdc-select-component-dialog .cdk-dialog-container {
    background: transparent;
}

.rdc-sidebar-panel {
    transform-origin: left center;
    margin-left: rem(8px);
    width:rem(280px);

    cdk-dialog-container {
        border-radius: 0;
        border-top: none;
        border-bottom: none;
    }
}

@keyframes openDialog {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
