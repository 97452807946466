@use "sass-rem" as *;

input[type="text"], input[type="search"], input[type="password"], input[type="date"], input[type="file"], select, textarea {
    border: solid var(--border-width) var(--border-outline-100);
    outline: none;
    background: var(--bg-body-100);
    border-radius: var(--border-radius);
    width: 100%;
    text-align: left;
    padding: 0 rem(12px);
    -webkit-appearance: none;
    -moz-appearance: none;
    height: rem(40px);
    color: var(--text-600);

    &:focus {
        border-color: var(--focus);
        color: var(--text-1100);
    }

    &.ng-invalid {
        border-color: var(--colour-danger-300);
    }

    &[disabled] {
        pointer-events: none;
        color: var(--text-600);
        background: var(--disabled);
    }

}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

input[type="number"] {
    border: solid var(--border-width) var(--border-outline-100);
    outline: none;
    background: var(--bg-body-100);
    border-radius: var(--border-radius);
    width: 40px;
    text-align: left;
    padding: 0 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: var(--text-600);

    &:focus {
        border-color: var(--focus);
        color: var(--text-1100);
    }

    &.ng-invalid {
        border-color: var(--colour-danger-300);
    }

    &[disabled] {
        pointer-events: none;
        color: var(--text-600);
        background: var(--disabled);
    }
}

input[disabled]::-webkit-input-placeholder {
    color: var(--text-400);
}
input[disabled]::-moz-placeholder {
    color: var(--text-400);
}
input[disabled]::-ms-placeholder {
    color: var(--text-400);
}
input[disabled]::placeholder {
    color: var(--text-400);
}


input[type="text"].ng-invalid.ng-touched,
input[type="search"].ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
    border-color: var(--colour-danger-300);
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input:focus::placeholder {
    color: transparent;
}

textarea {
    height: unset;
    padding: rem(12px);
}

select {
    cursor: pointer;
}

small.error {
    color: var(--colour-danger-300);
}

label {
    display: flex;
    flex-direction: column;
    gap: rem(4px);

    span {
        display: flex;
        gap: 5px;
    }

    rdc-apps-icon {
        cursor: pointer;
    }
}

label.rdc-checkbox {
    width: unset;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: rem(8px);
    cursor: pointer;

    input[type="radio"], input[type="checkbox"] {
        cursor: pointer;
    }

    &.disabled {
        opacity: 0.65;
        cursor: default;
        pointer-events: none;

        input {
            cursor: default;
        }
    }
}

label.text-hidden {
    gap:0;
}

label.text-hidden > span {
    height: 0;
    font-size: 0;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

label.compact, .icon-input.compact {
    input[type="text"], input[type="search"] {
        height: 32px;
    }

    rdc-apps-icon {
        height: rem(32px);
    }
}

.icon-input {
    width: 100%;
    border:none;
    outline:none;
    position: relative;
    user-select: none;

    input, select {
        width: 100%;
        padding-right: rem(40px);
        z-index: 99;
        position: relative;
    }

    rdc-apps-icon {
        color: var(--text-200);
        height: rem(40px);
        padding: 0 rem(8px);
        pointer-events: none;
        position: absolute;
        z-index: 99;
        right: 0;
        opacity: 0.8;
    }
    .clear {
        color: var(--text-200);
        height: rem(40px);
        top: 0;
        width: 38px;
        z-index: 100;
        padding: 0 rem(8px);
        position: absolute;
        right: 0;
        opacity: 0.8;
    }
}

.select-trigger {
    color: var(--text-600);
    cursor: pointer;
    width: 100%;
    justify-content: start;
    padding-right: 40px;
    font-weight: normal;
    position: relative;
    z-index: 90;

    span {
        font-weight: normal;
    }

    &:hover {
        background: var(--bg-body-100);
    }
    &:disabled {
        color: var(--text-400);
        background: var(--disabled);
    }
}

label.input-disabled {
    opacity: 0.7;
    cursor: default;

    span {
        cursor: default;
    }
    input, input[type="checkbox"], input[type="radio"] {
        cursor: default;
    }
}
