@use "sass-rem" as *;

input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    //background-color: green;
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: var(--primary-100);
    width: rem(16px);
    min-width: rem(16px);
    height: rem(16px);
    min-height: rem(16px);
    border: 1px solid var(--bg-body-700);
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
}

input[type="radio"]::before {
    content: "";
    width: rem(8px);
    height: rem(8px);
    border-radius: 50%;
    transform: scale(0);
    transition: 100ms transform ease-in-out;
    box-shadow: inset 1em 1em var(grey);
    /* Windows High Contrast Mode */
    background-color: var(--primary-100);
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

input[type="radio"]:focus {
    outline: none;
}
