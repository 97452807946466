@use "sass-rem" as *;

.cdk-menu {
    right: 0;
    animation: growDown 150ms ease-in-out forwards;
    transform-origin: top center;
    box-shadow: var(--drop-shadow-200);
    border-radius: var(--border-radius);
    display: inline-block;
    background: var(--bg-body-100);
    border: solid var(--border-width) var(--border-outline-100);
    transform: scaleY(1) scaleX(1);
}
.cdk-menu.with-margin {
    margin: rem(10px) rem(9px);
}

.cdk-menu.no-animate {
    animation: none;
}

.cdk-menu.above-menu {
    margin: rem(7px) rem(9px);
}

.cdk-menu.autocomplete, .cdk-menu.rdc-select-menu {
    animation: none;
    position: relative;
    margin: 0;
    max-height: rem(400px);
    overflow: auto;
}

@keyframes growDown {
  0% {
    transform: scaleY(0) scaleX(0);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}
