//import all rdc functions
@use "functions/color";
@use "functions/strip-unit";
@use "functions/text-size";

// import all rdc mixins
@use "mixins/breakpoint";
@use "mixins/input";
@use "mixins/keyframe";
@use "mixins/link";
@use "mixins/placeholder";
@use "mixins/visually-hidden";

// import rdc variables
@use "variables";

// import all rdc component styles
@use "rdc-link";
@use "rdc-button";
@use "rdc-menu-item";
@use "rdc-input";
@use "rdc-checkbox";
@use "rdc-radio";
@use "rdc-toggle";
@use "rdc-datatable";

// rdc-styles for angular cdk
@use "cdk-menu";
@use "cdk-dialog";

@use "sass-rem" as *;
// required for context menu and dialogs
@use '@angular/cdk/overlay-prebuilt.css';

// global rdc styles
* {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}

html, body {
    height:100%;
    background: var(--bg-body-400);
    color: var(--text-600);
}

strong {
    font-weight: var(--font-weight-bold);
}

hr {
    border: none;
    border-top: solid var(--border-width) #29293D;
    border-bottom: solid var(--border-width) #434366;
}

small {
    font-size: var(--font-size-10);
}

.container {
    width: 100%;
    max-width: rem(896px);
    padding: 0 rem(16px);
    margin: auto;
}

.autocomplete-backdrop {
    background: transparent;
    animation: none;
    transition: none;
}

.rdc-loading-spinner {
    width: 24px;
    height: 24px;
    border: 2px solid var(--text-300);
    border-bottom-color: var(--primary-100);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: spin 1s linear infinite;
}
.rdc-loading-spinner.sm {
    width: 18px;
    height: 18px;
}

// we want this to be above all else
.cdk-menu-carrot, .rdc-tooltip {
    z-index: 9999;
}

.rdc-sidebar-carrot {
    margin-left: rem(-2px);
}

.text-tooltip {
    font-size: var(--font-size-10);
    padding: 5px;
    background: var(--tooltip-background);
    border-radius: 3px;
    color: var(--text-300);

    * {
        color: var(--text-300);
        font-size: var(--font-size-10);
    }

    h4 {
        font-size: var(--font-size-12);
    }
}

.rdc-lined-title {
    display: flex;
    align-items: center;

    span {
        color: var(--text-500);
        font-weight: normal;
    }

    &:before {
        content: "";
        width: rem(8px);
        border-bottom: solid var(--border-width) var(--border-outline-100);
        margin-right: rem(8px);
    }

    &:after {
        content: "";
        flex: 1 1;
        border-bottom: solid var(--border-width) var(--border-outline-100);
        margin-left: rem(8px);
    }

    &.error {
        gap: 5px;

        * {
            color: var(--colour-danger-300);
        }
    }
}

.cdk-from-center-right {
    transform-origin: center right;
}
.cdk-from-center-left {
    transform-origin: center left;
}
.cdk-from-bottom-left {
    transform-origin: bottom left;
}
.cdk-from-bottom-center {
    transform-origin: bottom center;
}
.cdk-from-bottom-right {
    transform-origin: bottom right;
}
.cdk-from-top-left {
    transform-origin: top left;
}
.cdk-from-top-right {
    transform-origin: top right;
}

.empty-row {
    font-weight: bold;
    color: var(--text-300);
    text-align: center;
    margin-top: 20px;
    font-size: var(--font-size-16);
}

@keyframes spin {
    0%  { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}

@keyframes growDown {
    0% {
        transform: scaleY(0) scaleX(0);
    }
    100% {
        transform: scaleY(1) scaleX(1);
    }
}

.colours .color-picker, .qb-series-item .color-picker {
    border-radius: var(--border-radius);
    border: var(--border-width) solid var(--border-outline-100);
    box-shadow: var(--drop-shadow-200);

    .arrow.arrow-bottom {
        border-width: 8px 5px;
        margin-top: 3px;
        border-top-color: rgba(0, 0, 0, 0);
        border-right-color: rgba(0, 0, 0, 0);
        border-bottom-color: var(--border-outline-100);
        border-left-color: rgba(0, 0, 0, 0);

        &:before {
            content: "";
            position: absolute;
            top: 1px;
            display: block;
            left: -4px;

            border-style: solid;
            border-width: 0px 4px 8px 4px;

            border-top-color: rgba(0, 0, 0, 0);
            border-right-color: rgba(0, 0, 0, 0);
            border-bottom-color: var(--bg-body-100);
            border-left-color: rgba(0, 0, 0, 0);

        }
    }

    .arrow.arrow-top {
        border-width: 8px 5px;
        border-top-color: var(--border-outline-100) ;
        border-right-color: rgba(0, 0, 0, 0);
        border-bottom-color: rgba(0, 0, 0, 0);
        border-left-color: rgba(0, 0, 0, 0);

        &:before {
            content: "";
            position: absolute;
            bottom: 1px;
            display: block;
            left: -4px;

            border-style: solid;
            border-width: 8px 4px 0px 4px;

            border-top-color: var(--bg-body-100) ;
            border-right-color: rgba(0, 0, 0, 0);
            border-bottom-color: rgba(0, 0, 0, 0);
            border-left-color: rgba(0, 0, 0, 0);

        }
    }


    .saturation-lightness {
        height: 148px;
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);
    }

    .hue-alpha {
        padding: 8px;
        margin: 0;

        .left {
            display: none;
        }

        .right {
            padding: 0;

            div:first-child {
                display: none;
            }

            .hue {
                border-radius: var(--border-radius);
                margin-bottom: 0;
                height: 12px;
            }
        }
    }

    .hex-text {
        padding: 0;

        .box {
            padding: 0 8px;

            input {
                border-radius: var(--border-radius);
                border-color: var(--border-outline-100);
                font-size: var(--font-size-12);
                color: var(--text-600);

                &:focus-visible {
                    outline: 0;
                    border-color: var(--focus);
                }
            }

            &:last-child {
                display: none;
            }
        }
    }

    .type-policy {
        display: none;
    }

    .preset-area {
        padding: 8px;

        hr {
            display: none;
        }

        .preset-label {
            text-transform: uppercase;
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-8);
            color: var(--text-600);
            padding: 0;
        }

        .preset-color {
            border: none;
            border-radius: 100%;
            height: 12px;
            width: 12px;
            margin: 0;
        }

        .cp-preset-colors-class {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;
            padding-top: 4px;
        }
    }
}

.uqb-table-footer {
    padding: 16px;
    background: var(--bg-body-100);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .load-more {
        cursor: pointer;
        color: var(--primary-100);
    }
}

.rdc-query-running-overlay {
    position: absolute;
    width: 100%;
    z-index: 100;
    height: 100%;
    background: rgba(243,245,250, 0.7);
}
.rdc-query-running-overlay.snapshots {
    background: rgba(233,237,248, 0.7);
}

ng-scrollbar[viewClass="rdc-ng-scroll-aside"] > div {
    width: calc(100% + 12px);
}

.rdc-tag-filter-rounded {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    min-width: 12px;
    width: 12px;
    max-width: 12px;
    max-height: 12px;
    height: 12px;
    background: var(--primary-200);
    user-select: none;
}

.rdc-tag-filter-rounded.selected-tick {
    opacity: 0;

    rdc-apps-icon {
        color: var(--bg-body-100);
    }
}

.rdc-tag-filter-rounded.selected-tick.on {
    opacity: 1;
}

.applet-non-display {
    font-size: var(--font-size-14);
    line-height: var(--line-spacing-20);
    color: var(--text-600);
    margin-bottom: 8px;

    a {
        display: inline;
        font-size: var(--font-size-14);
    }
}

#app-header {
    position: sticky;
    top: 45px;
    z-index: 1000;
}
