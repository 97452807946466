@use "sass-rem" as *;

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;

    cursor: pointer;
    font: inherit;
    color: var(--primary-100);
    width: rem(16px);
    min-width: rem(16px);
    height: rem(16px);
    border: rem(1px) solid var(--bg-body-700);
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
}


input[type="checkbox"]::before {
    content: "";
    width: rem(8px);
    height: rem(8px);
    transform: scale(0);
    transition: 50ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary-100);
}

input.indeterminate::before {
    height: rem(2px);
    width: rem(8px);
    transform: scale(1);
    transition: 50ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--bg-body-700);
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input.indeterminate:checked::before {
    height:rem(8px);
    width: rem(8px);
    box-shadow: inset 1em 1em var(--primary-100);
}

input[type="checkbox"]:focus {
    outline: none;
}

input[type="checkbox"]:disabled {
    color: var(--primary-100);
    cursor: not-allowed;
}

