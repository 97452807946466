@use "sass-rem" as *;

.rdc-button {
    padding: 0 rem(12px);
    height: rem(40px);
    font-weight: var(--font-weight-semi-bold);
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    gap: rem(8px);
    align-items: center;
    background: var(--bg-body-100);
    color: var(--primary-100);
    border-radius: rem(5px);
    text-decoration: none;
    line-height: initial;
    border: solid var(--border-width) var(--border-outline-100);
    white-space: nowrap;
    overflow: hidden;

    span {
        font-weight: var(--font-weight-semi-bold);
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }

    &:hover, &.active {
        background: var(--bg-body-500);
    }

    &:focus, &:focus-visible {
        outline: none;
    }

    &[disabled] {
        color: var(--text-400);
    }
}

.rdc-button.btn-compact {
    height: rem(32px);
}

.rdc-button.btn-large {
    height: rem(40px);
}

.rdc-button.btn-icon {
    padding: 0 rem(6px);
}

.rdc-button.btn-close-dialog {
    position: absolute;
    top: rem(16px);
    right: rem(16px);
    height: rem(24px);
    width: rem(24px);
}

.rdc-button.btn-primary {
    background: var(--primary-100);
    color: var(--bg-body-100);
    border: none;
    padding: 0 rem(16px);

    &:hover {
        background: var(--primary-200);
    }

    &.uppercase {
        text-transform: uppercase;
    }

    &[disabled] {
        background: var(--bg-body-800);
        color: var(--text-100);
    }
}
.rdc-button.btn-secondary {
    color: var(--primary-100);
    background: var(--bg-body-600);
    border: none;

    &:hover {
        color: var(--primary-200);
        background: var(--bg-body-800);
    }
}

.rdc-button.btn-alternative {
    border-color: transparent;
    background: var(--bg-body-900);
    color: var(--text-200) !important;

    rdc-apps-icon {
        filter: var(--filter-text-200) !important;
    }


    &[disabled] {
        color: var(text-200);
        border-color: var(--border-outline-100);
    }

    &:hover {
        background: rgba(54,54,82,0.9);
        color: var(--text-100) !important;

        rdc-apps-icon {
            filter: var(--filter-white) !important;
        }
    }
}

.rdc-button.ng-invalid {
    border: solid var(--border-width) var(--colour-danger-300);
}

.rdc-button.btn-pill,
.rdc-button.btn-pill-alt,
.rdc-button.btn-pill-hollow,
.rdc-button.btn-pill-clear {
    background-color: var(--bg-body-600);
    border-radius: rem(20px);
    border: 0;
    padding: 0 rem(10px);
    height: rem(32px);
    cursor: pointer;

    &:hover {
        background-color: var(--bg-body-800);
    }
}

.rdc-button.btn-pill {
    color: var(--text-link-200);
}

.rdc-button.btn-pill-alt {
    color: var(--text-100);
}
.rdc-button.btn-pill-hollow {
    background: none;
    border:1px solid var(--border-outline-100);
}
.rdc-button.btn-pill-clear {
    height: rem(24px);
    background: none;
    border:1px solid var(--primary-100);
    &:hover {
        background-color: var(--bg-body-100);
    }
}

/* unused currently*/
.rdc-button.btn-accent {
  background: var(--accent-100);
  color: var(--bg-body-300);
  border: none;
}

.rdc-button[disabled] {
    pointer-events: none;
    user-select: none;
}

.btn-left-menu {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-right-menu {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rdc-select-value-button {
    text-align: left;
    font-weight: normal;
    justify-content: flex-start;
    color: var(--text-800);

    rdc-apps-icon {
        color: var(--primary-100);
    }
}

.rdc-video-close {
    top: -10px !important;
    right: -10px !important;
}
