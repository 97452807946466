@use "apex/apex-rsi-study";

html {
  // variable colours
  --accent-100: #43E08C;
  --accent-200: #79c292;

  --primary-100: #4D66F2;
  --primary-200: #3D51C1;

  // element must be #000 before applying filter
  --filter-white: brightness(0) invert(1);

  --filter-accent-100: invert(69%) sepia(80%) saturate(384%) hue-rotate(90deg) brightness(97%) contrast(84%);
  --filter-accent-200: invert(73%) sepia(18%) saturate(682%) hue-rotate(88deg) brightness(93%) contrast(93%);

  --filter-primary-100: invert(33%) sepia(100%) saturate(1086%) hue-rotate(213deg) brightness(94%) contrast(101%);;
  --filter-primary-200: invert(34%) sepia(12%) saturate(6238%) hue-rotate(208deg) brightness(89%) contrast(99%);
  --filter-primary-300: invert(44%) sepia(44%) saturate(470%) hue-rotate(107deg) brightness(96%) contrast(87%);

  --filter-text-200: invert(91%) sepia(8%) saturate(546%) hue-rotate(197deg) brightness(99%) contrast(91%);;
  --filter-text-300: invert(89%) sepia(14%) saturate(314%) hue-rotate(192deg) brightness(92%) contrast(84%);
  --filter-text-400: invert(83%) sepia(13%) saturate(333%) hue-rotate(186deg) brightness(82%) contrast(85%);
  --filter-text-500: invert(55%) sepia(7%) saturate(819%) hue-rotate(173deg) brightness(87%) contrast(90%);
  --filter-text-600: invert(45%) sepia(7%) saturate(999%) hue-rotate(183deg) brightness(96%) contrast(92%);

  --filter-default-icon: invert(57%) sepia(8%) saturate(544%) hue-rotate(185deg) brightness(95%) contrast(87%);
  --filter-default-icon-2: invert(57%) sepia(15%) saturate(293%) hue-rotate(185deg) brightness(93%) contrast(89%);
  --filter-active-cloud: invert(21%) sepia(12%) saturate(1540%) hue-rotate(201deg) brightness(91%) contrast(91%);

  --filter-link-100:  invert(74%) sepia(19%) saturate(650%) hue-rotate(195deg) brightness(94%) contrast(87%);
  --filter-play: invert(89%) sepia(10%) saturate(829%) hue-rotate(90deg) brightness(108%) contrast(97%);

  --bg-body-100: #ffffff;
  --bg-body-200: #F3F5FA;
  --bg-body-300: #F6F8FA;
  --bg-body-400: #EBECF3;
  --bg-body-500: #EEF1F9;
  --bg-body-600: #DCDCF2;
  --bg-body-700: #C2C7D9;
  --bg-body-800: #C1C9FB;
  --bg-body-900: #363652;
  --bg-body-1000: #1C2980;
  --bg-body-1100: #313E99;
  --bg-body-1200: #48CBF0;
  --bg-body-1300: #DADFFB;

  --sidebar-700: #51517A;
  --sidebar-800: #363652;

  --text-100: #ffffff;
  --text-200: #DCDFF2;
  --text-300: #C2C7D9;
  --text-400: #A4ABBE;
  --text-500: #727F8F;
  --text-600: #687287;
  --text-700: #424C57;
  --text-800: #444444;
  --text-900: #282829;
  --text-1000: #000000;

  // new contrast colour more to add
  --text-1100: #363652;

  --text-link-100:  #A7B0E2;
  --text-link-200: #586bef;

  --focus: #48CBF0;

  --colour-success-100: #F4FAF6;
  --colour-success-200: #EEF9F3;
  --colour-success-300: #79C292;
  --colour-success-400: #3E7A52;

  --colour-warn-100: orange;
  --colour-warn-200: orange;

  --colour-danger-100: #FAE8E8;
  --colour-danger-200: #e5b5b8;
  --colour-danger-300: #DA5555;
  --colour-danger-400: #8F2121;

  --colour-favourite-100: #F2C535;

  --colour-neutral-100: #e9ebfa;
  --colour-hover: #DCDFF2;
  --error-notification-bg: #f7e8e8;

  --border-outline-100: #DCDFF2;
  --border-outline-200: #c2c7d9;
  --border-outline-hover: #4d66f2;

  --border-radius: 4px;
  --border-radius-8: 8px;
  --border-width: 1px;
  --border-width-x2: 2px;

  --drop-shadow-100: 2px 0px 0px #DCDFF2;
  --drop-shadow-200: 0px 5px 5px #00000014;
  --drop-shadow-300: 0px 2px 2px #DCDFF2;
  --disabled: #F8F9FB;

  --tooltip-background: #363652;
  --banner-indicator: #A4E5F8;

  // typography
  --font-family-nunito: Nunito;
  --font-style-normal: normal;
  --font-weight-normal: normal;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-size-8: 8px;
  --font-size-9: 9px;
  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-32: 32px;
  --character-spacing-0: 0px;
  --character-spacing-0-25: 0.25px;
  --character-spacing-0-50: 0.50px;
  --line-spacing-12: 12px;
  --line-spacing-16: 16px;
  --line-spacing-20: 20px;
  --line-spacing-24: 24px;
  --line-spacing-32: 32px;
  --text-transform-uppercase: uppercase;

}

body * {
  font-family: var(--font-family-nunito);
  font-size: var(--font-size-12);
}

span {
    display: flex;
    align-items: center;
}

h1, h2, h3, h4, h5, h6 {
    display: flex;
    align-items: center;
}

h1 {
    font-size: var(--font-size-32);
}

h3, h3 span {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-22);
    color: var(--text-500);
}

h4, h4 span {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-16);
    color: var(--text-500);
}

h5, h5 span {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-14);
    color: var(--text-600);
}

p {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-14);
    line-height: var(--line-spacing-16);
    color: var(--text-500);

    strong {
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-semi-bold);
    }
}

strong {
    font-size: var(--font-size-14);
}

.placeholder-feature-em {
    color: var(--primary-100); display: inline; font-weight: bold;
}

