@use "../../../../../../../../node_modules/sass-rem/index" as *;

.rsi-route {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    max-width: 832px;
    border: var(--border-width) solid var(--border-outline-100);

    section {
        display: flex;
        gap: 16px;
        padding: rem(16px);
        position: relative;

        &:first-child, &:last-child {
            grid-area: 1;
            flex-direction: column;
        }

        &:first-child {
            border-right: solid var(--border-width) var(--border-outline-100);
        }

        .title {
            position: absolute;
            top: -9px;
            background: var(--bg-body-500);

            &:before, &:after {
                content: '';
                width: 8px;
                height: 2px;
                position: absolute;
                background: var(--bg-body-500);
            }

            &:before {
                left: -8px;
            }
            &:after {
                right: -8px;
            }
        }

        .label {
            width: 160px;
            gap: 4px;
            color: var(--text-600);
        }

        rdc-apps-autocomplete, rdc-apps-select, .select-container {
            position: relative;
            width: 216px;
        }

    }
}

.rsi-form {
    position: relative;
}

.rsi-flex {
    display: flex;

    &.timeframe {
        width: 216px;
    }

    &.column {
        flex-direction: column;
        gap: 8px;

        .wrapper {
            width: 216px;
        }
    }
}

.rsi-route-optional-params {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.rsi-selections {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 6px;

    &-container {
        width: 216px;
    }
}

.rsi-tooltip {
    display: flex;
    gap: 8px;
    cursor: help;
}

.assumptions-section {
    display: flex;
    justify-content: flex-end;
    max-width: 830px;

    .container-section {
        height: rem(24px) !important;
        background-color: transparent;
        border: none;
        border-radius: rem(2px);
        color: var(--primary-100);
        padding: rem(8px) 0;
        margin: rem(5px) 0;

        &:hover {
            background-color: var(--colour-hover);
        }

        &.assumptions-button {
            width: rem(104px) !important;
            padding: rem(8px) 0;
            color: var(--primary-100);
            font-size: var(--font-size-12);

            p {
                color: inherit;
                font-size: var(--font-size-12);
            }
        }
    }
}


