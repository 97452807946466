@use "sass-rem" as *;

.rdc-switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;

    &.large {
        height: 24px;
        width: 42px;

        input:checked + .rdc-slider:before {
            -webkit-transform: translateX(18px);
            -ms-transform: translateX(18px);
            transform: translateX(18px);
        }

        .rdc-slider:before {
            height: 18px;
            width: 18px;
            bottom: 3px;
            left: 3px;
        }
    }

    &.small {
        width: 22px;
        height: 12px;

        input:checked + .rdc-slider:before {
            -webkit-transform: translateX(9px);
            -ms-transform: translateX(9px);
            transform: translateX(9px);
        }

        .rdc-slider:before {
            height: 9px;
            width: 9px;
            bottom: 2px;
            left: 2px;
        }
    }
}

.rdc-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.rdc-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg-body-700);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 17px;
}

.rdc-slider:before {
    border-radius: 50%;
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .rdc-slider {
    background-color: var(--primary-100);
}

input:focus + .rdc-slider {
    box-shadow: 0 0 1px var(--primary-100);
}

input:checked + .rdc-slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}
